export default {
  SERVER_URL: "https://lemsi.institutointerculturas.com/backend/api",
  PAYPAL_CLIENT_ID:
    "ATxB3_4Jn9x-nrD6zr_eGWQWdPJlQyavu6uog4culJrcymNfy80M5Dr6ZhzAwxPuK3bZA4iqnGGDIqfr",
  ZOOM_ASSETS_URL: "https://lemsi.institutointerculturas.com/lib",
  WEBSOCKET_URL: "wss://lemsi.institutointerculturas.com/ws",
  MAXFILESIZE: 262144000, // 250 MB, in Bytes
  LOGGER_OPTIONS: {
    isEnabled: true,
    logLevel: "debug",
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: "|",
    showConsoleColors: true,
  },
  STOMP_DEBUG: false,
};
